// src/provider/OfficeProvider.js

import React, { createContext, useContext, useState } from "react";
import { useGetAllOffice } from "../hooks/office/useGetAllOffice";
import PropTypes from "prop-types";

const OfficeContext = createContext(undefined);

export const useOffice = () => {
  const context = useContext(OfficeContext);
  if (!context) {
    throw new Error("useOffice must be used within an OfficeProvider");
  }
  return context;
};

const OfficeProvider = ({ children }) => {
  const [offices, setOffices] = useState([]);
  console.log("offices", offices);

  const { data, isLoading, isSuccess, isError, error } = useGetAllOffice({
    setOffices,
  });

  console.log("data office", data);

  return (
    <OfficeContext.Provider
      value={{ offices, isLoading, isSuccess, isError, error }}
    >
      {children}
    </OfficeContext.Provider>
  );
};

OfficeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OfficeProvider;
