import React, { createContext, useContext, useState } from "react";
import { useGetRegion } from "../hooks/region/useGetRegion";
import PropTypes from "prop-types";

const RegionContext = createContext(undefined);

export const useRegion = () => {
  const context = useContext(RegionContext);
  if (!context) {
    throw new Error("useRegion must be used within an RegionProvider");
  }
  return context;
};

const RegionProvider = ({ children }) => {
  const [regions, setRegions] = useState([]);
  console.log("regions", regions);

  const { data, isLoading, isSuccess, isError, error } = useGetRegion({
    setRegions,
  });

  console.log("data", data);

  // const fetchRegions = () => {
  //   refetch();
  // };

  return (
    <RegionContext.Provider
      value={{ regions, isLoading, isSuccess, isError, error }}
    >
      {children}
    </RegionContext.Provider>
  );
};

RegionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RegionProvider;
