import { httpClient } from "../../api/httpClient";

function getAllOffice(params) {
  return httpClient.get("/offices/all", { params });
}

function getOffice(id) {
  return httpClient.get(`/office/${id}`);
}

function getSomeOffice() {
  return httpClient.get("/offices/all?search=&page=1&page_size=4&status=NONE");
}

export const OfficeServices = {
  getAllOffice,
  getOffice,
  getSomeOffice,
};
