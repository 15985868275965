import { lazy } from "react";

const ProtectedRoutes = [
  {
    path: "/",
    component: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    path: "/product/:id",
    component: lazy(() => import("../pages/Home/Product")),
    exact: true,
  },
  {
    path: "/booking",
    component: lazy(() => import("../pages/Booking/Booking")),
    exact: true,
  },
];

export default ProtectedRoutes;
