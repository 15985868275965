import { useQuery } from "@tanstack/react-query";
import { RegionServices } from "../../services/Region/Region.url";

export function useGetRegion({ setRegion, ...props }) {
	return useQuery({
		queryKey: ["regions"],
		queryFn: async () => {
			const response = await RegionServices.getRegion();

			console.log("API Response:", response);

			const regionsArray = response.data?.data;

			console.log("Region Array:", regionsArray);

			if (!Array.isArray(regionsArray)) {
				throw new Error("Fetched data is not an array");
			}
			setRegion(regionsArray);
		},
		...props,
	});
}