import "./App.css";
import AppRoute from "./routes/appRoute";
import AuthProvider from "./provider/AuthProvider";
import OfficeProvider from "./provider/OfficeProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RegionProvider from "./provider/RegionProvider";

// Membuat instance dari QueryClient
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <OfficeProvider>
          <RegionProvider>
            <AppRoute />
          </RegionProvider>
        </OfficeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
