import axios from "axios";
import { API_BASE_URL } from "../constants/apiConstant";

const baseURL = "https://ceosuite-api.nikici.com/api/v1";
console.log("API Base URL:", baseURL);
const interceptors = {
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  responseType: "json",
};

export const httpClient = axios.create({
  ...interceptors,
  baseURL,
});

httpClient.interceptors.request.use((request) => {
  const token = localStorage.getItem("access_token");

  request.headers["Authorization"] = "Bearer " + token;

  return request;
});

httpClient.interceptors.response.use(
  async (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    if (error.response?.status === 401) {
      try {
        localStorage.clear();
        window.location = "/login";
      } catch (errorResponse) {
        return Promise.reject(errorResponse);
      }
    }
    return Promise.reject(error);
  },
);
