import { useQuery } from "@tanstack/react-query";
import { OfficeServices } from "../../services/Office/Office.url";

export function useGetAllOffice({ setOffices, ...props }) {

  return useQuery({
    queryKey: ["offices"],
    queryFn: async () => {
      const response = await OfficeServices.getAllOffice();

      console.log("API Response:", response);

      const officesArray = response.data?.data?.result;

      console.log("Office Array:", officesArray);

      if (!Array.isArray(officesArray)) {
        throw new Error("Fetched data is not an array");
      }

      setOffices(officesArray);
    },
    ...props,
  });
}