export const setAuthLocalStorageData = (method, user, refreshToken, accessToken) => {
  if (method === "set" && user && refreshToken && accessToken) {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    localStorage.setItem("user", user);
  }
  if (method === "remove") {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }
};
